<template>
	<div class="pagination-container" :class="{notAnimated: !animatedSlide}">
		<div v-if="!hideHeader" class="top-bar-pagination" :class="{transparent, 'no-back': currentPageIndex === 0, 'no-next': (!pages.length || currentPageIndex === pages.length - 1)}">
			<div @click="previousPage" class="back-button" :class="{invisible: noNavigationButtons}">
				<AppChevronIcon class="chevron-back" direction="left" />
				<span>{{ getString('back') }}</span>
			</div>
			<div class="pagination-title">
				<p class="txt-title-2">{{ currentPage.title ? currentPage.title : "" }}</p>
			</div>
			<div @click="nextPage" class="next-button" :class="{invisible: noNavigationButtons}">
				<span>{{ getString('next') }}</span>
				<AppChevronIcon class="chevron-next" direction="right" />
			</div>
		</div>
		<div class="pagination-content-wrapper">
			<div class="pagination-content" :style="'--current-page:'+currentPageIndex">
				<div v-for="(singleItem, index) in pages" class="page-content" :ref="'page-'+index" :style="pageHeight(index)">
					<slot :name="singleItem.slotName" :id="singleItem.id" :item="singleItem"></slot>
				</div>
			</div>
		</div>
		<div v-if="!hideDots" class="bottom-bar-pagination">
			<div class="pages-dots">
				<div
					v-for="(page, index) in pages"
					:key="index"
					class="page-dot"
					:class="{active: index === currentPageIndex}"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import AppChevronIcon from "@/shared/components/AppChevronIcon.vue";
import {useLanguageStore, useScriptStore} from "@/shared/stores";

export default {
	setup() {
		const languageStore = useLanguageStore();
		return {getString: languageStore.getString};
	},
	name: "AppPagination",
	components: {AppChevronIcon},
	emits: ["changing-page", "changed-page", "clicked-next", "clicked-previous"],
	data() {
		return {
			currentPageIndex: 0,
			slidingTimeout: null,
			animatedSlide: true,
		}
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		pages: {
			type: Array,
			default: [],
		},
		transparent: {
			type: Boolean,
			default: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideDots: {
			type: Boolean,
			default: false,
		},
		changeHeight: {
			type: Boolean,
			default: false,
		},
		noNavigationButtons: {
			type: Boolean,
			default: false,
		},
		pageIndex: {
			type: Number,
			default: null,
		},
	},
	methods: {
		pageHeight(index) {
			if(this.changeHeight) {
				//DOING height transition
				/*let currentHeight = this.$refs['page-'+index] && this.$refs['page-'+index][0] && this.$refs['page-'+index][0].scrollHeight ? this.$refs['page-'+index][0].scrollHeight + 'px' : 'auto';
				console.log(this.$refs['page-'+index])
				console.log(currentHeight);*/
				return 'max-height:'+(this.currentPageIndex === index ? 'auto' : '0');
			}
			return ''
		},
		nextPage() {
			let canGoFurther = this.currentPageIndex < this.pages.length - 1;
			let newPos = canGoFurther ? this.currentPageIndex + 1 : 0;
			this.$emit("clicked-next", newPos);
			this.changeSlide(newPos);
		},
		previousPage() {
			let canGoBack = this.currentPageIndex > 0;
			let newPos = canGoBack ? this.currentPageIndex - 1 : this.pages.length - 1;
			this.$emit("clicked-previous", newPos);
			this.changeSlide(newPos);
		},
		changeSlide(newPos, animated = true) {
			this.currentPageIndex = newPos;
			this.$emit("changing-page", this.currentPageIndex);
			if(animated) {
				if(this.slidingTimeout) { clearTimeout(this.slidingTimeout); }
				this.slidingTimeout = setTimeout(() => {
					this.$emit("changed-page", this.currentPageIndex);
					this.slidingTimeout = null;
				}, 500);
			} else {
				this.$emit("changed-page", this.currentPageIndex);
				this.animatedSlide = false;
				setTimeout(() => {
					this.animatedSlide = true;
				}, 100);
			}
		}
	},
	computed: {
		currentPage() {
			return this.pages[this.currentPageIndex];
		},
	},
	mounted() {
		if(this.pageIndex !== null) {
			this.changeSlide(this.pageIndex);
		}
	},
	watch: {
		pageIndex(newVal, oldVal) {
			if(newVal !== null && newVal !== oldVal) {
				this.changeSlide(newVal);
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.top-bar-pagination, .bottom-bar-pagination {
	@include centered;
	@include noSelect;
	width: 100%;
	padding: 10px 0;
}
.top-bar-pagination {
	display: flex;
	justify-content: space-between;
	position: relative;

	background: var(--color-bg-primary-2);
	padding: 10px 12px;
	margin-bottom: 10px;
	border-radius: 13px;

	&.transparent {
		background: transparent;
	}

	.back-button, .next-button {
		@include noSelect;
		@include centered;
		@include justifyFlex(start);
		cursor: pointer;
		& span {
			margin-left: 5px;
			color: $primary;
		}
	}
	.next-button {
		& span {
			margin-left: 0;
			margin-right: 5px;
		}
		&:hover .chevron-next {
			transform: translateX(3px);
		}
	}
	&.no-back .back-button, &.no-next .next-button {
		visibility: hidden;
	}
	.back-button .chevron-back, .next-button .chevron-next {
		transition: transform 0.3s $transition;
	}
	.back-button:hover .chevron-back {
		transform: translateX(-3px);
	}

	.invisible {
		visibility: hidden;
	}
	@media all and (max-width: 480px) {
		.pagination-title { padding: 0 10px }
	}
	@media all and (max-width: 400px) {
		.back-button span, .next-button span {
			display: none;
		}
	}

}
.bottom-bar-pagination {
	padding-bottom: 0;
	padding-top: 15px;
}

.pages-dots {
	@include centered;
	max-width: 100%;
	flex-wrap: wrap;
	.page-dot {
		@include noSelect;
		width: 5px;
		height: 5px;
		border-radius: 2.5px;
		background-color: $primary;
		opacity: .3;
		margin: 0 4px 4px;
		transition: opacity .5s $transition, width .5s $transition;
		&.active {
			width: 12px;
			opacity: .6;
		}
	}
}

.pagination-content-wrapper {
	padding: 0;
	width: 100%;
	overflow: hidden;
	position: relative;
}

.pagination-content {
	width: 100%;
	display: flex;
	transition: transform 0.5s;
	transform: translateX(calc((-100% * var(--current-page)) - (10% * (var(--current-page) + 1))));
}
.page-content {
	flex: 0 0 100%;
	display: flex;
	min-height: 100px;
	flex-direction: column;
	max-width: 100%;
	transition: max-height .3s ease;
	margin-left: 10%;
}

.pagination-title {
	width: 100%;
	padding: 0 20px;
	text-align: center;
}

.pagination-container {
	&.notAnimated {
		.pagination-content {
			transition: none;
		}
	}
}

</style>